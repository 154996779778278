<template>
  <div id="app" data-server-rendered="true">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="less">
#app{
  width: 100%;
  box-sizing: border-box;
}

</style>
