import store from '@/store'
import { getToken } from '@/utils/auth' // getToken from cookie
import router from '@/router'
router.beforeEach((to, from, next) => {
  if (getToken()) {
    if (!store.getters.roles.length) {
      // 判断当前用户是否已拉取完user_info信息
      store.dispatch('GetInfo').then(res => { // 拉取user_info
        return store.dispatch('GenerateRoutes')
      }).then(() => {
        router.addRoutes(store.getters.addRouters)
        next()
      })
        .catch(() => {
          store.dispatch('FedLogOut').then(() => {
            next({ path: '/' })
            location.reload()
          })
        })
    } else {
      next()
    }
  } else {
    next()
  }
})

router.afterEach(() => {
})
