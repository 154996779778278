import './../../public-path'
import Vue from 'vue'
import App from './App.vue'
import 'babel-polyfill'
import './permission' // permission control
import './../../sentry'
import { GDKye } from 'api/config'
import VueAMap from 'vue-amap'
import 'normalize.css/normalize.css'
import '@/filters/filters'
import '@/icons' // icon
// A modern alternative to CSS resets
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/shixiang.less'
import '@/styles/common.less'
import router from '@/router'
import store from './../../store'
import { setting } from '@/setting'
import GImage from '@/components/GImage'
import { linkInsidePages } from './../config'
import '@/imgMerge'
import '../../directives'


(function (d) {
  if (navigator.userAgent.indexOf('MSIE 9') === -1) return

  d.addEventListener('selectionchange', function () {
    var el = d.activeElement
    if (el.tagName === 'TEXTAREA' || (el.tagName === 'INPUT' && el.type === 'text') || (el.tagName === 'INPUT' && el.type === 'number') || (el.tagName === 'INPUT' && el.type === 'password')) {
      var ev = d.createEvent('CustomEvent')
      ev.initCustomEvent('input', true, true, {})
      el.dispatchEvent(ev)
    }
  })
})(document)
if (Number.parseInt === undefined) Number.parseInt = window.parseInt
if (Number.parseFloat === undefined) Number.parseFloat = window.parseFloat


setTimeout(() => {
  localStorage.clear()
  Vue.use(VueAMap)
})

Vue.use(ElementUI)
Vue.use(GImage)
Vue.config.productionTip = false
Vue.prototype.$baseInfo = setting.baseInfo
Vue.prototype.$PRERENDER_INJECTED = window.__PRERENDER_INJECTED__ !== 'prerender'
Vue.prototype.$linkInsidePages = linkInsidePages

new Vue({
  router,
  store,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('prerender-render-event'))
  }
}).$mount('#app')
store.dispatch('getPageConfig')

if (window.__PRERENDER_INJECTED__ !== 'prerender') {
  VueAMap.initAMapApiLoader({
    key: GDKye,
    plugin: ['AMap.Autocomplete', 'AMap.DistrictSearch', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.Geocoder', 'Geolocation', 'CitySearch'],
    // 默认高德 sdk 版本为 1.4.4
    v: '1.4.4',
    events: {
      init (o) {
      }
    }
  })
}

